
import swal from "sweetalert";
import {
    ADD_USER,
    CLOSE_USER_ALERT, DELETE_USER,
    EDIT_USER, FILTER_USER,
    FORCE_REFRSH,
    GET_USERS,
    OPEN_USER_ALERT, SEARCH_USER
} from "../../constants/userConstants";
import api, {logout} from "../../constants/api";
import {getToken} from "../../reducers/userReducers";
import store from "../../store";

export default function userReducer(
    state = {
        users: [],
        isUserLoaded: false,
        isUserAlertOpend: false,
        selectedUser: null,
    },
    action
) {
    switch (action.type) {
        case GET_USERS:
            return (state = {
                ...state,
                isUserLoaded: true,
                selectedUser: null,
                isUserAlertOpend: false,
                users: action.payload,
            });
        case ADD_USER: {
            return {
                ...state,
                isUserLoaded: true,
                isUserAlertOpend: false,
                selectedUser: null,
                users: [action.payload, ...state.users],
            };
        }

        case OPEN_USER_ALERT: {
            return {
                ...state,
                isUserLoaded: true,
                isUserAlertOpend: true,
                selectedUser: action.payload,
            };
        }
        case CLOSE_USER_ALERT: {
            return {
                ...state,
                isUserLoaded: true,
                isUserAlertOpend: false,
                selectedUser: null,
            };
        }
        case FORCE_REFRSH : {
            return {
                ...state,

                isUserLoaded: true,
                isUserAlertOpend: false,
                selectedUser: null,
                users: [...state.users],
            };
        }

        case EDIT_USER: {
            var index = 0;
            [...state.users].forEach((value, i) => {
                if (value.id === action.payload.id) {
                    index = i;
                }
            });
            state.users[index] = action.payload;

            return {
                ...state,
                isUserLoaded: true,
                isUserAlertOpend: false,
                selectedUser: null,
                users: [...state.users],
            };
        }
        case SEARCH_USER: {
            var temp = state.users.filter((test) =>
                test.name.toLowerCase().includes(action.payload.toLowerCase())
            );

            return {
                ...state,
                isUserLoaded: true,
                isUserAlertOpend: false,
                selectedUser: null,
                users: [...state.users],
            };
        }
        case FILTER_USER: {
            temp = state.users.filter(
                (test) => test.category.id === action.payload.id
            );

            return {
                ...state,
                isUserLoaded: true,
                isUserAlertOpend: false,
                selectedUser: null,
                users: [...state.users],
            };
        }
        case DELETE_USER : {
            const newList = state.users.filter(
                (item) => item.id !== action.payload.id
            );

            return {
                ...state,
                isUserLoaded: true,
                isUserAlertOpend: false,
                selectedUser: null,
                users: [...newList],
            };
        }
        default:
            return state;
    }
}

export async function getUsers() {
    const config = {
        headers: {Authorization: `Bearer ${getToken()}`},
    };
    try {
        const response = await api.get("users?_take=10000000", config);
        const data = response.data;
        store.dispatch({type: GET_USERS, payload: data});
    } catch (error) {
        if (error.response?.status === 401) {
            logout();
        }
    }
}

export async function addUser(accountData) {
    const config = {
        headers: {Authorization: `Bearer ${getToken()}`},
    };
    try {
     
        const response = await api.post(
            "users/create",
            accountData,
            config
        );

        const data = response.data;

        document.getElementById("createUserFormId").reset();
        store.dispatch({type: ADD_USER, payload: data});
        await swal("Good job!", "user was added successfully", "success");
    } catch (error) {
        
        if (error.response?.status === 422) {

            const errors = JSON.parse(JSON.stringify(error.response.data.errors))
            

            
            let str = "";
            Object.keys(errors).forEach(function (key) {
                
                str = str + errors[key][0] + "\n";
            });
            await swal("Opps!", "Failed to update user\n" + str, "error");
            return;
        }


        if (error.response?.status === 401) {
            logout();
        }
        await swal("Opps!", "Failed to add user", "error");
    }
}

export async function upDateUser(
    state
    , id
) {
    
    try {
        const response = await api.put(
            "users/update/" + id,
            state,
            {
                headers: {Authorization: `Bearer ${getToken()}`},
            }
        );

        const data = response.data;

        store.dispatch({type: EDIT_USER, payload: data});
        swal("Good job!", "user was updated successfully", "success");
    } catch (error) {
        if (error.response?.status === 422) {

            const errors = JSON.parse(JSON.stringify(error.response.data.errors))
            

            
            let str = "";
            Object.keys(errors).forEach(function (key) {
                
                str = str + errors[key][0] + "\n";
            });
            await swal("Opps!", "Failed to update user\n" + str, "error");
            return;
        }

        if (error.response?.status === 401) {
            logout();
        }
        await swal("Opps!", "Failed to update user", "error");
    }
}

export async function deleteUser(id) {
    const config = {
        headers: {Authorization: `Bearer ${getToken()}`},
    };
    try {
        const response = await api.delete(
            "users/delete/" + id,

            config
        );

        const data = response.data;

        store.dispatch({type: DELETE_USER, payload: {id: id}});
        swal("Good job!", "user was deleted successfully", "success");
    } catch (error) {
        if (error.response?.status === 401) {
            logout();
        }
        await swal("Opps!", "Failed to delete user", "error");
    }
}
