import React, {useEffect} from "react";
import "./adminStyle.css";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import swal from "sweetalert";

import {deleteUser, getUsers} from "./usersReducer";

export default function User() {
    const {users} = useSelector(
        (state) => state.userReducer
    );

    useEffect(() => {
        if (users.length === 0) {
            getUsers();
        }
    }, []);
    let index = 1;

    const handleClick = (e) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this user",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (users.length == 1) {
                    swal("Opps!", "Failed to delete user, you have only one user in the system", "error");


                    return;
                }
                if (willDelete === true) {
                    
                    deleteUser(e.id);

                } else {

                }
            });

    };

    let history = useHistory();

    const handleEdit = (e) => {
        history.push("/editUser", e);
    };
    const listItems = users.map((d) => (
        <tr key={index}>
            <td>{index++}</td>
            <td>{d.fullname}</td>
            <td>{d.phone}</td>
            <td>{d.username}</td>
            <td scope="row">
                <button
                    type="button"
                    className="btn btn-outline-danger waves-effect px-3"
                    onClick={(e) => {
                        handleClick(d);
                    }}
                >
                    <i className="fas fa-user-times" aria-hidden="true">
                        {" "}
                    </i>
                </button>
                <span> </span>
                <button
                    type="button"
                    onClick={(e) => {
                        handleEdit(d);
                    }}
                    className="btn btn-outline-info waves-effect px-3"
                >
                    <i className="far fa-edit" aria-hidden="true">
                        {" "}
                    </i>
                </button>
            </td>
        </tr>
    ));
    return (
        <div className="topSpace">
            <div className="card rounded-top">
                <div className="card-header bg-info">User</div>
                <div className="card-body">
                    <section className="">
                        <form className="form mb-3">
                            <div className="row center ">
                                <div className="col-6">
                                    <Link type="button" className="btn btn-info w-100" to="/register">
                                        <i className="fas fa-plus"></i> Add
                                    </Link>
                                </div>
                            </div>
                        </form>
                        <table className="table">
                            <thead className="topthrade">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Email</th>
                                <th scope="col">
                                    <span> Action</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>{listItems}</tbody>
                        </table>
                    </section>

                </div>
            </div>
        </div>
    );
}