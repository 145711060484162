import React, {Component, useState} from "react";
import "./accountStyle.css";
import {Link} from "react-router-dom";
import {addUser} from "./usersReducer";

const AddUserForm = () => {
    const [state, setState] = useState({
        fullname: "",
        phone: "",
        password: "",
        confirmPassword: "",
        username: "",
        user_type: "lab-staff",
    });

    const [error, setError] = useState({
        fullname: "",
        phone: "",
        password: "",
        confirmPassword: "",
        username: "",
        user_type: "",
    });


    const handSubmit = (evt) => {
        evt.preventDefault();
        if (
            error.fullname === "" &&
            error.password === "" &&
            error.confirmPassword === "" &&
            error.phone === "" &&
            error.username === "" && validate() === true
        ) {
            
            addUser(
                state
            );
        }else{
            
        }
    };
    const handleUsernameChanged = (evt) => {
        setState({...state, username: evt.target.value});
        if (evt.target.value.length > 6) {
            setError({...error, username: ""});
        } else {
            setError({...error, username: "username must be at least 6 character"});

        }
    };

    const validate = () => {
        let tempError = error;
        if (state.fullname === "") {
            tempError = {...tempError, fullname: "fullname is required"};
        } else if (state.fullname.length < 7) {
            tempError = {...tempError, fullname: "fullname must be at least 7 character"};
        } else {
            tempError = {...tempError, fullname: ""};
        }
        if (state.phone === "") {
            tempError = {...tempError, phone: "phone is required"};
        } else if (state.phone.length !== 11) {
            tempError = {
                ...tempError,
                phone: "phone must be 11 digit"
            };
        } else {
            tempError = {...tempError, phone: ""};
        }
        if (state.password === "") {
            tempError = {
                ...tempError,
                password: "password is required",
            };
        } else if (state.password.length < 8) {
            tempError = {
                ...tempError,
                password: "password must be more than 7 character",
            };
        } else {
            tempError = {...tempError, password: ""};
        }

        if (state.username === "") {
            tempError = {
                ...tempError,
                username: "username is required",
            };
        } else if (state.username.length < 8) {
            tempError = {
                ...tempError,
                username: "username must be more than 7 character",
            };
        } else {
            tempError = {...tempError, username: ""};
        }


        
        
        if (
            tempError.fullname === "" &&
            tempError.phone === "" &&
            tempError.username === "" &&
            tempError.password === "" &&
            tempError.confirmPassword === "" &&
            tempError.user_type === ""
        ) {
            return true;
        } else {
            setError(tempError);
            return false;
        }
    };


    const handleRoleChanged = (evt) => {
        setState({...state, user_type: evt.target.value})
    };
    const handleFullname = (evt) => {
        setState({...state, fullname: evt.target.value});
        if (evt.target.value.length > 6) {
            setError({...error, fullname: ""});
        } else {
            setError({...error, fullname: "fullname must be more than 6 character"});
        }
    };
    const handlePhone = (evt) => {
        setState({...state, phone: evt.target.value});
        if (evt.target.value.length === 11) {
            setError({...error, phone: ""});
        } else {
            setError({...error, phone: "phone number must be 11 digit"});
        }
    };

    const handlePassword = (evt) => {
        setState({...state, password: evt.target.value});
        let temp = {...error};
        if (evt.target.value.length > 7) {
            temp = ({...temp, password: ""});
        } else {
            temp = ({...temp, password: "password must be at least 8 character"});
        }
        if (state.password !== state.confirmPassword) {
            temp = ({...temp, confirmPassword: "confirmed password does not match the password"})
        } else {
            temp = ({...temp, confirmPassword: ""});
        }

        setError({...temp})
        
    };
    const handleConfirmPassword = (evt) => {
        setState({...state, confirmPassword: evt.target.value});
        if (evt.target.value.length < 8) {
            setError({...error, confirmPassword: "confirmed password must be at least 8 character"});

        } else if (state.password !== evt.target.value) {
            setError({...error, confirmPassword: "confirmed password does not match the password"});


        } else {
            setError({...error, confirmPassword: ""});
        }
    };
    return (
        <div className="content-wrapper center topSpace">
            <div className="register-box">
                <div className="card card-outline card-info">
                    <div className="card-header text-center">
                        <Link to="/lab" className="h1">
                            <b>Medical</b>.IT
                        </Link>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Create user Account</p>
                        <form
                            onSubmit={(e) => {
                                handSubmit(e);
                            }}
                            id="createUserFormId"
                        >
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={state.fullname}
                                    placeholder="fullname"
                                    onChange={handleFullname}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user"/>
                                    </div>
                                </div>
                            </div>
                            {" "}
                            <div style={{fontSize: 12, color: "red"}}>{error.fullname}</div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={state.username}
                                    placeholder="Email"
                                    onChange={handleUsernameChanged}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"/>
                                    </div>
                                </div>
                            </div>
                            <div style={{fontSize: 12, color: "red"}}>{error.username}</div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={state.phone}
                                    placeholder="phone"
                                    onChange={handlePhone}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas"/>
                                    </div>
                                </div>
                            </div>
                            {" "}
                            <div style={{fontSize: 12, color: "red"}}>{error.phone}</div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Password"
                                    defaultValue={state.password}
                                    onChange={handlePassword}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"/>
                                    </div>
                                </div>
                            </div>

                            <div style={{fontSize: 12, color: "red"}}>{error.password}</div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Retype password"
                                    defaultValue={state.confirmPassword}
                                    onChange={handleConfirmPassword}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"/>
                                    </div>
                                </div>
                            </div>
                            {" "}
                            <div style={{fontSize: 12, color: "red"}}>
                                {error.confirmPassword}
                            </div>
                            <div className="form-group row">
                                <label
                                    htmlFor="inputGender"
                                    className="col-sm-4 col-form-label"
                                >
                                    User Roles
                                </label>
                                <div className="col-sm-10">
                                    <select
                                        defaultValue={state.user_type}
                                        className="form-control col-sm-5"
                                        id="inputGender"
                                        onChange={handleRoleChanged}
                                    >
                                        <option value="lab-staff">Staff</option>
                                        <option value="lab-admin">Admin</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-info btn-block">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUserForm;
