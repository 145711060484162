import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getOccupationAction} from "../../actions/occupationAction";
import {getRefDoctor} from "../../actions/referalDoctor";
import {addPationAction} from "../../actions/pationactions";
import Select from "react-select";
import showLoading from "../alert/show_alert";
import './newInvoceStyle.css'
var dateFormat = require("dateformat");

function NewInvoice() {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        birthdate: new Date(),
        fullName: "",
        phoneNumber: "",
        age: "",
        gender: "Male",
        smoking: "0",
        alkohol: "0",
        marital_status: "single",
        occupation: "",
        refDoctor: "",
        address: "",
        patientCV: [],
        appointment: new Date(),
        blood_type: "A+",
    });
    const [age, setAge] = useState("")
    const [error, setError] = useState({
        fullName: "",
        phoneNumber: "",
        age: "",

    });
    const [fileList, setFileList] = useState([]);

    const getOccoupation = useSelector((state) => state.getOccupation);
    const userlogin = useSelector((state) => state.userLogin);
    const referalDoctor = useSelector((state) => state.getDoctor.referalDoctor);
    const {userInfo} = userlogin;
    const {getOccupations} = getOccoupation;
    const smokingHanlder = () => {
        setState({...state, smoking: state.smoking === "0" ? "1" : "0"});
    };
    const alkoholHandler = () => {
        setState({...state, alkohol: state.alkohol === "0" ? "1" : "0"});
    };
    const GetFormattedDate=()=> {
        var todayTime = new Date();
        var month = (todayTime .getMonth() + 1);
        var day = (todayTime .getDate());
        var year = (todayTime .getFullYear());
        
        return year + "-" +month  + "-" +day;
    }
    useEffect(() => {
        dispatch(getOccupationAction(userInfo.token));
        dispatch(getRefDoctor(userInfo.token));
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        
        GetFormattedDate();

        const {userInfo} = userlogin;
        if (validate() === false) {
            return;
        }

        showLoading();

        addPationAction(
            state.fullName,
            state.phoneNumber,
            state.birthdate.toISOString().split("T")[0],
            state.gender,
            state.blood_type,
            state.address,
            state.occupation.value ?? "",
            state.refDoctor.value ?? "",
            state.marital_status,
            state.alkohol,
            state.smoking,
            dateFormat(new Date(), "yyyy-mm-dd HH:mm:ss"),
            state.patientCV,
            resetState
        );
    };
    
    const resetState = () => {
        

        setFileList([])
        setError({
            fullName: "",
            phoneNumber: "",
            age: "",

        });
        setState({
            birthdate: new Date(),
            fullName: "",
            phoneNumber: "",
            age: "",
            gender: "Male",
            smoking: "0",
            alkohol: "0",
            marital_status: "single",
            occupation: "",
            refDoctor: "",
            address: "",
            patientCV: "",
            appointment: new Date(),
            blood_type: "A+",
        });
        document.getElementById("resetStateId").reset();
    };

    const validate = () => {
        let tempError = error;
        if (state.fullName === "") {
            tempError = {...tempError, fullName: "required"};
        } else if (state.fullName.length < 4) {
            tempError = {...tempError, fullName: "short name"};
        } else {
            tempError = {...tempError, fullName: ""};
        }
        if (state.phoneNumber === "") {
            tempError = {...tempError, phoneNumber: "required"};
        } else if (state.phoneNumber.length !== 11) {
            tempError = {
                ...tempError,
                phoneNumber: "phone must be 11 digit",
            };
        } else {
            tempError = {...tempError, phoneNumber: ""};
        }


        
        
        if (
            tempError.fullName === "" &&
            tempError.phoneNumber === ""
        ) {
            return true;
        } else {
            setError(tempError);
            return false;
        }
    };

    function getAge(dateString) {
        var nowDate = new Date(new Date().setHours(0, 0, 0, 0));
        // Example date of birth.
        var dobDate = new Date(dateString);

        var years = nowDate.getFullYear() - dobDate.getFullYear();
        var months = nowDate.getMonth() - dobDate.getMonth();
        var days = nowDate.getDate() - dobDate.getDate();

        // Work out the difference in months.
        months += years * 12;
        if (days < 0) {
            months -= 1;
        }
        // Now add those months to the date of birth.
        dobDate.setMonth(dobDate.getMonth() + months);
        // Calculate the difference in milliseconds.
        let diff = nowDate - dobDate;
        // Divide that by 86400 to get the number of days.
        var days = Math.round(diff / 86400 / 1000);
        // Now convert months back to years and months.
        years = parseInt(months / 12);
        months -= years * 12;

        let text = "";
        if (years) {
            text = years + (years > 1 ? " years" : " year");
        }
        if (months) {
            if (text.length) {
                text = text + ", ";
            }
            text = text + months + (months > 1 ? " months" : " month");
        }
        if (days) {
            if (text.length) {
                text = text + ", ";
            }
            text = text + days + (days > 1 ? " days" : " day");
        }
        if (nowDate === dobDate) {
            text = "Newborn";
        }
        if (
            text === "" ||
            text === null ||
            text === undefined ||
            days < 0 ||
            months < 0 ||
            years < 0
        ) {
            text = "age can not be calculated";
        }
        setAge(text)
        
    }

    let occupationOptions = [];

    getOccupations?.forEach((occupation) => {
        occupationOptions.push({value: occupation.id, label: occupation.name});
    });

    let refDoctorOptions = [];
    if (referalDoctor.length !== 0) {
        referalDoctor?.forEach((refDoctor) => {
            refDoctorOptions.push({value: refDoctor.id, label: refDoctor.name});
        });
    }
    let files = [];
    fileList.forEach((e, index) => {
        files.push(
            <tr key={Math.floor(Math.random() * 435611)}>
                <td>
                    <img width="100" height="100" id="target" src={e.image}/>
                </td>
                <td className="text-center">
                    <button className="btn btn-danger" onClick={t => {
                        let temp = [];
                        for (let i = 0; i < fileList.length; i++) {
                            if (i !== index) {
                                temp.push(fileList[i])
                            }
                        }
                        let temp2 = [];
                        for (let i = 0; i < state.patientCV.length; i++) {
                            if (i !== index) {
                                temp2.push(state.patientCV[i])
                            }
                        }
                        setFileList([...temp])
                        setState({...state, patientCV: [...temp2]})
                    }}>
                        <i className="fas fa-trash"> </i>
                    </button>
                </td>
            </tr>
        );
    });
    return (
        <div className="card">
            <div className="card-header bg-info">Add New Patient</div>
            <div className="card-body">
                <form
                    id="resetStateId"
                    onSubmit={submitHandler}
                    className="text-center border border-light p-5"
                >
                    <div className="form-row mb-3">
                        <div className="col mr-3 text-left">
                            <label htmlFor="fullName">Full name</label>
                            <br/>
                            <input
                                type="text"
                                id="fullName"
                                className="form-control"
                                placeholder="Full name"
                                value={state.fullName}
                                onChange={(e) => {
                                    setState({...state, fullName: e.target.value});
                                    if (e.target.value === "" || e.target.value === undefined) {
                                        setError({...error, fullName: "required"});
                                    } else if (e.target.value.length < 4) {
                                        setError({...error, fullName: "short full name"});
                                    } else {
                                        setError({...error, fullName: ""});
                                    }
                                }}
                            />
                            <div style={{fontSize: 12, color: "red"}}>{error.fullName}</div>
                        </div>
                        <div className="col ml-3 text-left">
                            <label htmlFor="PhoneNumber">Phone number</label>
                            <br/>
                            <input
                                type="text"
                                id="PhoneNumber"
                                className="form-control"
                                placeholder="Phone number"
                                value={state.phoneNumber}
                                onChange={(e) => {
                                    setState({...state, phoneNumber: e.target.value});
                                    if (e.target.value === "" || e.target.value === undefined) {
                                        setError({...error, phoneNumber: "required"});
                                    } else if (e.target.value.length !== 11) {
                                        setError({...error, phoneNumber: "wrong phone number"});
                                    } else {
                                        setError({...error, phoneNumber: ""});
                                    }
                                }}
                            />
                            <div style={{fontSize: 12, color: "red"}}>
                                {error.phoneNumber}
                            </div>
                        </div>
                    </div>
                    {/*end first Row*/}

                    <div className="form-row mb-3 mt-4">
                        <div className="col mr-3 text-left">
                            <label className="col-form-label mr-3">Birthday</label>

                            <DatePicker
                                className="form-control"
                                selected={state.birthdate}
                                onChange={(date) => {
                                    

                                    if (date === null) {
                                        return;
                                    }
                                    getAge(date.toISOString().split(" ")[0])
                                    setState({
                                        ...state,
                                        birthdate: date,
                                        age: new Date().getFullYear() - date.getFullYear(),
                                    });
                                }}
                            />
                        </div>
                        <div className="col ml-3">
                            <input
                                disabled={true}
                                id="AgeWithNumber"
                                className="form-control"
                                placeholder="Age "

                                value={age}

                            />
                            <div style={{fontSize: 12, color: "red"}}>{error.age}</div>
                        </div>
                    </div>

                    {/*second row*/}
                    <div className="form-row mb-3">
                        <div className="col mr-3 text-left">
                            <label className="col-form-label">Gender</label>

                            <select
                                value={state.gender}
                                onChange={(e) => {
                                    setState({...state, gender: e.target.value});
                                }}
                                className="browser-default custom-select form-control"
                                placeholder="Gender"
                            >
                                <option value="Male" selected>
                                    Male
                                </option>
                                <option value="Female">Female</option>
                                <option value="Intersex">Intersex</option>
                            </select>
                        </div>
                        <div className="col ml-3 text-left">
                            <label className="col-form-label">Marital Status</label>

                            <select
                                value={state.marital_status}
                                onChange={(e) => {
                                    setState({...state, marital_status: e.target.value});
                                }}
                                className="browser-default custom-select form-control"
                                placeholder="Marital Status"
                            >
                                <option value="Male">Single</option>
                                <option value="Female">Married</option>
                                <option value="Intersex">Divorced</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row mb-3">
                        <div className="col mr-3 text-left">
                            <label>Alkohol</label>
                            <br/>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="Alkohol"
                                    checked={state.alkohol==1}
                                    onChange={alkoholHandler}

                                />
                            </Form>
                        </div>
                        <div className="col ml-3 text-left">
                            <label>Smoking</label>
                            <br/>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="smoking"
                                    value={state.smoking}
                                    checked={state.smoking==1}
                                    onChange={smokingHanlder}
                                />
                            </Form>
                        </div>
                    </div>
                    {/*third row*/}
                    <div className="form-row mb-3">
                        <div className="col mr-3 text-left">
                            <label className="col-form-label">occupation</label>
                            <div className="select2-purple">
                                <Select
                                    id="inputSupplier"
                                    classNamePrefix="select test"
                                    name="form-scientific_name_id-name"
                                    isDisabled={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    value={state.occupation}
                                    style={{borderRadius: '6px'}}

                                    onChange={(e) => {
                                        
                                        setState({...state, occupation: e});
                                        if (e !== null && e !== undefined) {
                                            setError({...error, occupation: ""});
                                        }
                                    }}
                                    options={occupationOptions}
                                />

                                {/*<select*/}
                                {/*    className="form-control select2bs4"*/}
                                {/*    name="state"*/}
                                {/*    value={state.occupation}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        setState({...state, occupation: e.target.value});*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {()}*/}
                                {/*</select>*/}

                            </div>
                        </div>
                        <div className="col ml-3 text-left">
                            <label className="col-form-label">Referral Doctor</label>
                            <Select
                                id="inputSupplier"
                                classNamePrefix="select test"
                                name="form-scientific_name_id-name"
                                isDisabled={false}
                                isClearable={false}
                                value={state.refDoctor}
                                isSearchable={true}
                                style={{borderRadius: '6px'}}
                                onChange={(e) => {
                                    setState({...state, refDoctor: e});
                                    if (e !== null && e !== undefined) {
                                        setError({...error, refDoctor: ""});
                                    }
                                }}
                                options={refDoctorOptions}
                            />
                            {/*<select*/}
                            {/*    className="form-control select2bs4"*/}
                            {/*    value={state.refDoctor}*/}

                            {/*>*/}
                            {/*    {()}*/}
                            {/*</select>*/}

                        </div>
                    </div>
                    {/*four row*/}

                    <div className="form-row mb-1">
                        <div className="col mr-3 text-left">
                            <label className="col-form-label">Address</label>

                            <input
                                type="text"
                                id="addressInput"
                                className="form-control"
                                placeholder="address "
                                value={state.address}
                                onChange={(e) => {
                                    setState({...state, address: e.target.value});
                                    if (e.target.value === "" || e.target.value === undefined) {
                                        setError({...error, address: "required"});
                                    } else if (e.target.value.length < 4) {
                                        setError({...error, address: "short address"});
                                    } else {
                                        setError({...error, address: ""});
                                    }
                                }}
                            />
                        </div>
                        <div className="col text-left">
                            <label className="col-form-label">blood type</label>

                            <select
                                value={state.blood_type}
                                onChange={(e) => {
                                    setState({...state, blood_type: e.target.value});
                                }}
                                className="browser-default custom-select form-control"
                                placeholder="blood ype"
                            >
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-"> AB-</option>
                            </select>
                        </div>
                    </div>
                    {/*five row*/}

                    <div className="form-row mb-3">


                        <div className="col ml-3 text-left">
                            <label className="col-form-label">Patient cv</label>

                            <div className="input-group mb-3">

                                <input
                                    className="form-control"

                                    onChange={(e) => {
                                        
                                        let temp = [];
                                        Array.from(e.target.files).forEach((e) => {
                                            
                                            temp.push({image: URL.createObjectURL(e)});
                                        });

                                        setFileList([...temp]);
                                        setState({...state, patientCV: [...e.target.files]});
                                    }}
                                    type="file"
                                    multiple
                                    // className="custom-file-input"
                                    id="inputGroupFile01"
                                    aria-describedby="inputGroupFile"
                                />
                                {/*<label*/}
                                {/*  className="custom-file-label"*/}
                                {/*  htmlFor="inputGroupFile01"*/}
                                {/*>*/}
                                {/*  Choose file*/}
                                {/*</label>*/}
                            </div>

                        </div>

                    </div>
                    <table className="table table-bordered">
                        <thead className="bg-info">
                        <tr>
                            <th>
                                Image
                            </th>
                            <th>
                                Delete
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        {files}
                        </tbody>
                    </table>


                    <button type="submit" className="btn btn-info w-50">
                        <span>Submit</span>
                    </button>
                </form>

            </div>
        </div>
    );
}

export default NewInvoice;
