import {
  ADD_LAB_REQUEST,
  ADD_LAB_SUCCESS,
  ADD_LAB_FAIL,
  GET_LAB_REQUEST,
  GET_LAB_SUCCESS,
  GET_LAB_FAIL,
  DELETE_LAB_REQUEST,
  DELETE_LAB_SUCCESS,
  DELETE_LAB_FAIL,
} from '../constants/labConstants';

export const addLabsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_LAB_REQUEST:
      return { loading: true };
    case ADD_LAB_SUCCESS:
      return { lodaing: false, addLab: action.payload };
    case ADD_LAB_FAIL:
      return { loading: false, error: 'Add Drugs fail ' };
    default:
      return state;
  }
};

export const getLabReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LAB_REQUEST:
      return { loading: true };
    case GET_LAB_SUCCESS:
      return { lodaing: false, getLabData: action.payload };
    case GET_LAB_FAIL:
      return { loading: false, error: 'Get Drugs fail ' };
    default:
      return state;
  }
};

export const deleteLabReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_LAB_REQUEST:
      return { loading: true };
    case DELETE_LAB_SUCCESS:
      return { lodaing: false, getLabData: action.payload };
    case DELETE_LAB_FAIL:
      return { loading: false, error: 'Delete Lab data fail ' };
    default:
      return state;
  }
};
