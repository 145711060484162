 
import swal from 'sweetalert';
import api from '../constants/api';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCESS,
  GET_ALL_USERS_FAIL,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCESS,
  DELETE_USERS_FAIL,
} from '../constants/userConstants';
import { getToken } from '../reducers/userReducers';
export const login = (username, password) => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const { data } = await api.post(
      'login',
      { username, password },
      config
    );
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    await swal("Opps!", "Failed to login ,incorrect username or password", "error");
    dispatch({
      type: USER_LOGIN_FAIL,
    });
  }
};

export const register = (
  fullname,
  phone,
  username,
  password,
  user_type
) => async dispatch => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const { data } = await api.post(
      'create',
      { fullname, phone, username, password, user_type },
      config
    );
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
   } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
    });
  }
};

export const getAlluser = token => async dispatch => {
  try {
    dispatch({
      type: GET_ALL_USERS_REQUEST,
    });

    const { data } = await api.get(
      'users',

      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer  ${getToken()}`,
        },
      }
    );

    dispatch({
      type: GET_ALL_USERS_SUCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAIL,
    });
  }
};

export const deleteUser = (token, id) => async dispatch => {
  try {
    dispatch({
      type: DELETE_USERS_REQUEST,
    });

    const { data } = await api.delete(
      `users/delete/${id}`,

      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer  ${getToken()}`,
        },
      }
    );
    dispatch({
      type: DELETE_USERS_SUCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USERS_FAIL,
    });
  }
};
