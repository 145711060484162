 import swal from "sweetalert";
import api from "../constants/api";
import {
  ADD_DRUGS_REQUEST,
  ADD_DRUGS_SUCCESS,
  ADD_DRUGS_FAIL,
  GET_DRUGS_REQUEST,
  GET_DRUGS_SUCCESS,
  GET_DRUGS_FAIL,
  DELETE_DRUGS_REQUEST,
  DELETE_DRUGS_SUCCESS,
  DELETE_DRUGS_FAIL,
  UPDATE_DRUGS_REQUEST,
  UPDATE_DRUGS_SUCCESS,
  UPDATE_DRUGS_FAIL,
} from "../constants/drugsConstants";
import { getToken } from "../reducers/userReducers";
import store from "../store";

export async function addDrugAction(name, description, reset) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.post(
      "drugs/create",
      { name, description },
      config
    );
    store.dispatch({
      type: ADD_DRUGS_SUCCESS,
      payload: data,
    });
    reset();
    await swal("Good job!", "Drug was added successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to add drugs", "error");

    store.dispatch({
      type: ADD_DRUGS_FAIL,
    });
  }
}

export const getDrugsAction = (token) => async (dispatch) => {
  try {
    store.dispatch({
      type: GET_DRUGS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.get(
      "drugs",
      config
    );
    store.dispatch({
      type: GET_DRUGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DRUGS_FAIL,
    });
  }
};

export async function deleteDrug(token, id) {
  try {
    store.dispatch({
      type: DELETE_DRUGS_REQUEST,
    });

    const { data } = await api.delete(
      `drugs/delete/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer  ${getToken()}`,
        },
      }
    );
    store.dispatch({
      type: DELETE_DRUGS_SUCCESS,
      payload: data,
    });
    document.getElementById("addDrugsFormId").reset();
    await swal("Good job!", "Drug was deleted successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to delete drugs", "error");
    store.dispatch({
      type: DELETE_DRUGS_FAIL,
    });
  }
}

export async function updateDrug(token, id, name, description, reset) {
  try {
    store.dispatch({
      type: UPDATE_DRUGS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.put(
      `drugs/update/${id}`,
      { name, description },
      config
    );
    store.dispatch({
      type: UPDATE_DRUGS_SUCCESS,
      payload: data,
    });
    reset();
    await swal("Good job!", "Drug was updated successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to update drugs", "error");
    store.dispatch({
      type: UPDATE_DRUGS_FAIL,
    });
  }
}
