import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./todayPatient.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTodayPatient, getPatient } from "./todayPatinetReducer";
import { createVisti, followUp } from "./EachVisition/create_visit_reducer";
import ReactPaginate from "react-paginate";

var dateFormat = require("dateformat");

function TodayPatientScreen() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [phoneSort, setPhoneSort] = useState(false);

  function changePhoneStateSort() {
    setPhoneSort(!phoneSort);
  }
  const dispatch = useDispatch();

  const { visits, isAll, total } = useSelector(
    (state) => state.todayPatinetReducer
  );
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (visits?.length === 0) {
      getAllTodayPatient();
    }
  }, []);

  const [search, setSearch] = useState("");
  let patientList = [];
  let index = 0;
  const GetFormattedDate = () => {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();

    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + day;
  };

  visits?.forEach((visit, indexIn) => {
    let ind = "";

    let color = false;

    if (
      GetFormattedDate() ===
        visit.visit_last_one.follow_date.toString().split(" ")[0] &&
      GetFormattedDate() !==
        visit.visit_last_one.created_at.toString().split(" ")[0]
    ) {
      color = true;
    } else {
      index = index + 1;
      ind = index;
    }

    patientList.push(
      <tr
        key={indexIn}
        style={color == true ? { backgroundColor: "DarkGray" } : {}}
      >
        <td>{ind}</td>
        <td>{visit.fullname}</td>
        <td>{visit.phone} </td>
        <td>{visit.code} </td>
        <td className="text-center">
          {GetFormattedDate() ===
          visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
            <button
              className="btn btn-outline-info"
              onClick={async (e) => {
                let isCreated = await createVisti(visit.id);

                if (isCreated == true) {
                  getPatient(startDate, endDate, search, page);
                }
              }}
            >
              New Visit
            </button>
          )}
        </td>

        <td className="text-center text-danger text-bold " id="sizeFont">
          {visit.cvs.length}
        </td>

        <td className="text-center text-danger text-bold " id="sizeFont">
          <ins>
            {" "}
            <Link
              className="text-red text-u"
              to={{
                pathname: `/tabsVisit/${visit.id}`,
                state: { patientKey: visit },
              }}
            >
              {" "}
              {visit.visits}
            </Link>
          </ins>
        </td>

        <td className="text-center">
          {GetFormattedDate() ===
          visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
            <button
              className="btn btn-outline-warning"
              onClick={(e) => {
                followUp(visit.id);
              }}
            >
              Follow up
            </button>
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className="rounded-top">
      <div className="card rounded-top">
        <div className="card-header bg-info">Today Patient</div>
        <div className="card-body">
          <div className="active-purple-4 mt-1 text-center">
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-md-6 text-center">
                <div className="row text-center">
                  <div className="">
                    <span className="m-2">From</span>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <div>
                    <span className="m-2">{"  "}To </span>
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-md-6">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    className="form-control my-0 py-1"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2">
              <button
                className="btn btn-primary w-50"
                onClick={(e) => {
                  setPage(0);
                  getPatient(startDate, endDate, search, 0);
                }}
              >
                search
              </button>
            </div>

            <div className="mt-2">
              <button
                className="btn btn-primary w-50"
                onClick={(e) => {
                  getAllTodayPatient();
                  setSearch("");
                }}
              >
                Get All Today Patinet
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mr-1 ml-1">
        <table className="table table-bordered w-100">
          <thead id="tableHeader" className="w-100">
            <tr>
              <th>#</th>
              <th>
                <span> Patient name</span>
                <span className="float-right">
                  {/*TODO when click on this , from up go to down and else.*/}
                  {phoneSort ? (
                    <i
                      className="fas fa-sort-up"
                      onClick={changePhoneStateSort}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-sort-down"
                      onClick={changePhoneStateSort}
                    ></i>
                  )}
                </span>
              </th>
              <th>
                <span>phone</span>
              </th>
              <th>
                <span>Code</span>
              </th>
              <th>
                <span> New Visit</span>
                <span className="float-right">
                  <i className="fas fa-sort-up"></i>
                  <i className="fas fa-sort-down"></i>
                </span>
              </th>
              <th>
                <span>Patient CVs</span>
              </th>
              <th>visitations</th>
              <th>Follow Up</th>
            </tr>
          </thead>
          <tbody>{patientList}</tbody>
        </table>
        {total > 0 && isAll == false ? (
          <div className="center">
            <ReactPaginate
              forcePage={0}
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              se
              breakClassName={"break-me"}
              onPageChange={(ev) => {
                getPatient(startDate, endDate, search, ev.selected);
                setPage(ev.selected);
              }}
              pageNum={page}
              forcePage={page}
              pageCount={total / 10}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <div>
            <br />
          </div>
        )}
      </div>
    </div>
  );
}

export default TodayPatientScreen;
