
import swal from "sweetalert";
import {
    ADD_USER,
    CLOSE_USER_ALERT, DELETE_USER,
    EDIT_USER, FILTER_USER,
    FORCE_REFRSH,
    GET_USERS,
    OPEN_USER_ALERT, SEARCH_USER
} from "../../constants/userConstants";
import api, {logout} from "../../constants/api";
import {getToken} from "../../reducers/userReducers";
import store from "../../store";

export default function reportReducer(
    state = {
        isLoaded: false,
        data: {total_visit_price: 0, total_patients: 0, male: 0, female: 0, child: 0}
    },
    action
) {
    switch (action.type) {
        case SET_REPORT_STATUS:
            return (state = {
                data: {total_visit_price: 0, total_patients: 0, male: 0, female: 0, child: 0},
                isLoaded:action.payload,
            });
        case SET_REPORT: {
            return {
                ...state,
                isLoaded: true,
                data: action.payload,

            };
        }

        default:
            return state;
    }
}

export async function getReport(startDate,endDate) {
    const config = {
        headers: {Authorization: `Bearer ${getToken()}`},
    };
    store.dispatch({type: SET_REPORT_STATUS, payload: false});
    try {
        const response = await api.get("clinic-reports/by-gender?form="+startDate+"&to="+endDate, config);
        const data = response.data;
        store.dispatch({type: SET_REPORT, payload: data});
    } catch (error) {
        if (error.response?.status === 401) {
            logout();
        }
    }
}

export const  SET_REPORT="SET_REPORT";

export const  SET_REPORT_STATUS="SET_REPORT_STATUS";
