import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addRefDoctor,
  deleteRefDoctor,
  getRefDoctor,
  updateRefDocto,
} from "../../actions/referalDoctor";

function ReferralDoctor() {
  const [doctorName, setDoctorName] = useState();
  const [phone, setPhone] = useState();
  const [updateId, setUpdateID] = useState();
  const dispatch = useDispatch();
  const userlogin = useSelector((state) => state.userLogin);
  const getAllRefDoctor = useSelector((state) => state.getDoctor);
  const { userInfo } = userlogin;
  const { referalDoctor } = getAllRefDoctor;

  useEffect(() => {
    dispatch(getRefDoctor(userInfo.token));
  }, []);
  const resetForm = () => {
    setDoctorName("");
    setPhone("");
    setUpdateID("");
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (updateId == null) {
      dispatch(addRefDoctor(userInfo.token, doctorName, phone, resetForm));
      await dispatch(getRefDoctor(userInfo.token));
    } else {
      await dispatch(
        updateRefDocto(userInfo.token, updateId, doctorName, phone, resetForm)
      );
      setUpdateID(null);

      await dispatch(getRefDoctor(userInfo.token));
    }
  };
  const [search, setSearch] = useState("");
  const deleteHandler = async (id) => {
    const { userInfo } = userlogin;
    
    await dispatch(deleteRefDoctor(userInfo.token, id));
    dispatch(getRefDoctor(userInfo.token));
  };
  const editdeleteHandler = (id, name, phone) => {
    setDoctorName(name);
    setPhone(phone);
    setUpdateID(id);
  };
  let count = 0;
  let getRefDoctorForm = [];

  referalDoctor?.forEach((refDoctor, index) => {
    if (refDoctor.name?.includes(search) || refDoctor.phone?.includes(search)) {
      count++;
      getRefDoctorForm.push(
        <tr key={refDoctor.id}>
          <td>{count}</td>
          <td>{refDoctor.name}</td>
          <td>{refDoctor.phone}</td>

          <td>
            <button
              onClick={() =>
                editdeleteHandler(refDoctor.id, refDoctor.name, refDoctor.phone)
              }
              className="bg-success btn-circle btn-sm mr-2"
            >
              <i className="fas fa-pen"></i>
            </button>
            <button
              type="button"
              onClick={() => deleteHandler(refDoctor.id)}
              className="bg-red btn-circle btn-sm"
            >
              <i className="fas fa-trash white"></i>
            </button>
          </td>
        </tr>
      );
    }
  });

  return (
    <div className="card">
      <div className="card-header bg-info">
        <p>Add Doctor</p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-5 col-sm-12">
            <form className="form" onSubmit={submitHandler}>
              <input
                type="text"
                className="form-control"
                id="doctorName"
                placeholder="Doctor name"
                value={doctorName}
                onChange={(e) => setDoctorName(e.target.value)}
              />
              <input
                type="text"
                className="form-control mt-3"
                id="doctorPhone"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <button type="submit" className="btn btn-primary w-100 mt-3">
                <i className="fas fa-plus"></i>{" "}
                {updateId == null || updateId == undefined ? "Add" : "Save"}
              </button>
            </form>{" "}
            <br/>
            {updateId != null && updateId != undefined ? (
              <div
                className="form-group"
                onClick={(e) => {
                  setDoctorName("");
                  setPhone("");
                  setUpdateID(null);
                }}
              >
                <button className="btn btn-danger w-100">Cancel</button>
              </div>
            ) : null}
          </div>
          <div className="col-lg-8 col-md-7 col-sm-12">
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search by Test"
                  aria-label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-prepend">
                  <span
                    className="input-group-text purple lighten-3"
                    id="basic-text1"
                  >
                    <i className="fas fa-search" aria-hidden="true" />
                  </span>
                </div>
              </div>
            </div>
            <table className="table table-bordered dataTable rounded-top mt-1">
              <thead className="rounded-top">
                <tr className="bg-gradient-cyan light rounded-top">
                  <th>#</th>
                  <th>Name</th>
                  <th>phone</th>
                  <th>action</th>
                </tr>
              </thead>
              <tbody>{getRefDoctorForm}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferralDoctor;
