import {
  ADD_OCCUPATION_REQUEST,
  ADD_OCCUPATION_SUCCESS,
  ADD_OCCUPATION_FAIL,
  GET_OCCUPATION_REQUEST,
  GET_OCCUPATION_SUCCESS,
  GET_OCCUPATION_FAIL,
  UPDATE_OCCUPATION_REQUEST,
  UPDATE_OCCUPATION_SUCCESS,
  UPDATE_OCCUPATION_FAIL,
} from '../constants/occupationConstatnts';

export const addOccupationReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_OCCUPATION_REQUEST:
      return { loading: true };
    case ADD_OCCUPATION_SUCCESS:
      return { lodaing: false, addOccupation: action.payload };
    case ADD_OCCUPATION_FAIL:
      return { loading: false, error: 'Add Occupation fail ' };
    default:
      return state;
  }
};

export const getOccupationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OCCUPATION_REQUEST:
      return { loading: true };
    case GET_OCCUPATION_SUCCESS:
      return { lodaing: false, getOccupations: action.payload };
    case GET_OCCUPATION_FAIL:
      return { loading: false, error: 'Get Occupation fail ' };
    default:
      return state;
  }
};

export const updateOccupationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_OCCUPATION_REQUEST:
      return { loading: true };
    case UPDATE_OCCUPATION_SUCCESS:
      return { lodaing: false, getOccupations: action.payload };
    case UPDATE_OCCUPATION_FAIL:
      return { loading: false, error: 'Get Occupation fail ' };
    default:
      return state;
  }
};
