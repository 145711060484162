export const ADD_DRUGS_REQUEST = 'ADD_DRUGS_REQUEST';
export const ADD_DRUGS_SUCCESS = 'ADD_DRUGS_SUCCESS';
export const ADD_DRUGS_FAIL = 'ADD_DRUGS_FAIL';

export const GET_DRUGS_REQUEST = 'GET_DRUGS_REQUEST';
export const GET_DRUGS_SUCCESS = 'GET_DRUGS_SUCCESS';
export const GET_DRUGS_FAIL = 'GET_DRUGS_FAIL';

export const DELETE_DRUGS_REQUEST = 'DELETE_DRUGS_REQUEST';
export const DELETE_DRUGS_SUCCESS = 'DELETE_DRUGS_SUCCESS';
export const DELETE_DRUGS_FAIL = 'DELETE_DRUGS_FAIL';

export const UPDATE_DRUGS_REQUEST = 'UPDATE_DRUGS_REQUEST';
export const UPDATE_DRUGS_SUCCESS = 'UPDATE_DRUGS_SUCCESS';
export const UPDATE_DRUGS_FAIL = 'UPDATE_DRUGS_FAIL';
