import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  saveMedicalHistory,
  updateMedicalHistory,
} from "../EachVisition/create_visit_reducer";

const HestoryVisit = (props) => {
  const sizeYes = {
    width: "15%",
    minWidth: "100px",
  };
  const sizeDrug = {
    width: "60%",
  };
  const [state, setState] = useState();
  let ex = props.state;
  let patient_id = props.patient_id;

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type + "Range"] = e.range;
        temp[e.disease] = e.note;
      });
      setState({ ...temp });
    } else {
      setState({
        Gravida: "",
        Pariety: "",
        Abortion: "",
        Dead: "",
        LengthofPregnancy: "",
        modeOfDelivery: "",
        aboutChildren: "",
        other: "",
        GravidaRange: "0",
        ParietyRange: "0",
        AbortionRange: "0",
        DeadRange: "0",
        LengthofPregnancyRange: "0",
        modeOfDeliveryRange: "0",
        aboutChildrenRange: "0",
        otherRange: "0",
      });
    }
  }, []);

  const handeSubmit = (e) => {
    e.preventDefault();

    let types = [];
    let ranges = [];
    let notes = [];
    Object.entries(state).forEach(([key, value]) => {
      if (key.toString().toLowerCase().includes("range")) {
        ranges.push(value?.toString());
      } else {
        types.push(key?.toString());
        notes.push(value?.toString());
      }
    });

    let ids = [];

    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });
      console.log(types, ranges, notes, patient_id, ids);
      updateMedicalHistory(types, ranges, notes, patient_id, ids);
    } else {
      console.log(types, ranges, notes, patient_id);
      saveMedicalHistory(types, ranges, notes, patient_id);
    }
  };

  return (
    <div>
      <form onSubmit={handeSubmit}>
        {/*top history*/}
        <section className="mt-2 rounded-lg">
          <table className="table table-bordered w-100  table-responsive">
            {/* <thead className="bg-info">
              <tr>
                <th className="w-25">Chronic Disease</th>
                <th style={sizeYes}>Yes/No</th>
                <th style={sizeDrug}>Drugs & Other Details</th>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td colSpan={3}>
                  <h3>Obstetrics</h3>
                </td>
              </tr>
              <tr>
                <td className="w-25">Gravida</td>
                <td className="text-center" style={sizeYes}>
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        as="select"
                        value={state?.GravidaRange}
                        onChange={(e) => {
                          setState({ ...state, GravidaRange: e.target.value });
                        }}
                      >
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td style={sizeDrug}>
                  <textarea
                    placeholder="Gravida"
                    value={state?.Gravida}
                    onChange={(e) => {
                      setState({ ...state, Gravida: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*9*/}
              <tr>
                <td>Pariety</td>
                <td className="text-center">
                  <Form>
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      value={state?.ParietyRange}
                      onChange={(e) => {
                        setState({ ...state, ParietyRange: e.target.value });
                      }}
                    >
                      <Form.Control as="select">
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Pariety"
                    value={state?.Pariety}
                    onChange={(e) => {
                      setState({ ...state, Pariety: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*10*/}
              <tr>
                <td>Abortion</td>
                <td className="text-center">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        as="select"
                        value={state?.AbortionRange}
                        onChange={(e) => {
                          setState({
                            ...state,
                            AbortionRange: e.target.value,
                          });
                        }}
                      >
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Abortion"
                    value={state?.Abortion}
                    onChange={(e) => {
                      setState({ ...state, Abortion: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*11*/}
              <tr>
                <td>Dead</td>
                <td className="text-center">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        as="select"
                        value={state?.DeadRange}
                        onChange={(e) => {
                          setState({ ...state, DeadRange: e.target.value });
                        }}
                      >
                        {[
                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        ].map((value) => (
                          <option>{value}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="Dead"
                    value={state?.Dead}
                    onChange={(e) => {
                      setState({ ...state, Dead: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*12*/}
              <tr>
                <td>Length of pregnancy</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="LengthOfPregnancySwitch"
                      checked={state?.LengthofPregnancyRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          LengthofPregnancyRange:
                            state?.LengthofPregnancyRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="LengthofPregnancy"
                    value={state?.LengthofPregnancy}
                    onChange={(e) => {
                      setState({ ...state, LengthofPregnancy: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*13*/}
              <tr>
                <td>mode of delivery</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="modeOfDeliverySwitch"
                      checked={state?.modeOfDeliveryRange === "1"}
                      onChange={(e) => {
                        setState({
                          ...state,
                          modeOfDeliveryRange:
                            state?.modeOfDeliveryRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="modeOfDelivery"
                    value={state?.modeOfDelivery}
                    onChange={(e) => {
                      setState({ ...state, modeOfDelivery: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*14*/}
              <tr>
                <td>about children</td>
                <td className="text-center">
                  <Form>
                    <Form.Check
                      type="switch"
                      checked={state?.aboutChildrenRange === "1"}
                      id="abouthChildrenSwitch"
                      onChange={(e) => {
                        setState({
                          ...state,
                          aboutChildrenRange:
                            state?.aboutChildrenRange === "0" ? "1" : "0",
                        });
                      }}
                    />
                  </Form>
                </td>
                <td>
                  <textarea
                    placeholder="aboutChildren"
                    value={state?.aboutChildren}
                    onChange={(e) => {
                      setState({ ...state, aboutChildren: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </td>
              </tr>
              {/*14*/}
            </tbody>
          </table>
        </section>
        {/*table history*/}
        <section className="mt-2">
          <div className="mt-2">
            {/*bithday wight*/}

            <hr />
            <div className="input-group input-group-lg mt-2">
              <div className="w-100">
                <label htmlFor="other">other</label>
                <textarea
                  placeholder="other"
                  value={state?.other}
                  onChange={(e) => {
                    setState({ ...state, other: e.target.value });
                  }}
                  className={`form-control shadow-none  ml-1 mr-1`}
                />
              </div>
            </div>
          </div>
        </section>

        <button className="btn btn-success btn-lg mt-3 w-100" type="submit">
          Submit
        </button>
      </form>{" "}
    </div>
  );
};

export default HestoryVisit;
