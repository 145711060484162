import swal from "sweetalert";
import { getToken } from "../../../../reducers/userReducers";
import api, { logout } from "../../../../constants/api";

export async function saveLastReport(note, visit_id) {
  
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
   
    const response = await api.post(
      "discharg-reports/create",
      {
        visit_id: visit_id,
        note: note,
      },
      config
    );

    const data = response.data;

    await swal("Good job!", " Report was saved successfully", "success");
    return true;
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save Report", "error");
    }
    return false;
  }
}

export async function updateLastReport(note, visit_id, id) {
  
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    
    const response = await api.put(
      "discharg-reports/update/" + id,
      {
        visit_id: visit_id,
        note: note,

      },
      config
    );

    const data = response.data;

    await swal("Good job!", "Report was saved successfully", "success");
    return true;
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save Report", "error");
    }
    return false;
  }
}
