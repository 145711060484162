import {
  ADD_DRUGS_REQUEST,
  ADD_DRUGS_SUCCESS,
  ADD_DRUGS_FAIL,
  GET_DRUGS_REQUEST,
  GET_DRUGS_SUCCESS,
  GET_DRUGS_FAIL,
  DELETE_DRUGS_REQUEST,
  DELETE_DRUGS_SUCCESS,
  DELETE_DRUGS_FAIL,
  UPDATE_DRUGS_REQUEST,
  UPDATE_DRUGS_SUCCESS,
  UPDATE_DRUGS_FAIL,
} from '../constants/drugsConstants';

export const addDrugsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_DRUGS_REQUEST:
      return { loading: true };
    case ADD_DRUGS_SUCCESS:
      return { lodaing: false, addDrug: action.payload };
    case ADD_DRUGS_FAIL:
      return { loading: false, error: 'Add Drugs fail ' };
    default:
      return state;
  }
};

export const getDrugsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DRUGS_REQUEST:
      return { loading: true };
    case GET_DRUGS_SUCCESS:
      return { lodaing: false, getDrugsData: action.payload };
    case GET_DRUGS_FAIL:
      return { loading: false, error: 'Get Drugs fail ' };
    default:
      return state;
  }
};

export const deleteDrugsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DRUGS_REQUEST:
      return { loading: true };
    case DELETE_DRUGS_SUCCESS:
      return { lodaing: false, getDrugsData: action.payload };
    case DELETE_DRUGS_FAIL:
      return { loading: false, error: 'Delete Drugs fail ' };
    default:
      return state;
  }
};

export const updateDrugsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DRUGS_REQUEST:
      return { loading: true };
    case UPDATE_DRUGS_SUCCESS:
      return { lodaing: false, getDrugsData: action.payload };
    case UPDATE_DRUGS_FAIL:
      return { loading: false, error: 'Update Drugs fail ' };
    default:
      return state;
  }
};
