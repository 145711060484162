import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { USER_LOGOUT } from '../../constants/userConstants';

function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem('userInfo');
    dispatch({
      type: USER_LOGOUT,
    });
    history.push('/loginForm');
  };
  return (
    <div className='wrapper'>
      <nav className='main-header navbar navbar-expand navbar-dark navbar-light fixed-top'>
        <ul className='navbar-nav'>
          <li className='nav-item'>
            <a
              className='nav-link'
              data-widget='pushmenu'
              href='www.google.com/'
              role='button'
            >
              <i className='fas fa-bars' />
            </a>
          </li>
        </ul>

        <div className='w-100'></div>
        <button
          className='btn nav-item btn-outline-light rounded-lg'
          onClick={logout}
        >
          logout
        </button>
      </nav>
    </div>
  );
}

export default Navbar;
