import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { saveExamination, updateExamination } from "./examination_operation";
import { useSelector } from "react-redux";

const ExaminationSection = (props) => {
  let isComplete = props.state.state == "completed";

  const [state, setState] = useState({});
  let ex = props.state?.examination;

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type] = e.result;
      });

      setState({ ...temp });
    } else {
      setState({
        lmp: Date(),
        EDD: new Date(),

        height: 0,
        weight: 0,
        BMI: 0,

        LMPText: "",
        eddText: "",
        otherGynecologyObstetric: "",
      });
    }
  }, []);

  const calculateBMI = (height, wight) => {
    let heightSquared = ((height / 100) * height) / 100;
    let bmi = round(wight / heightSquared, 1);

    return bmi;
  };
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  const onSubmit = (e) => {
    e.preventDefault();

    let types = [];
    let result = [];
    Object.entries(state).forEach(([key, value]) => {
      if (
        value.toString() != "" &&
        value.toString() != undefined &&
        value.toString() != null
      ) {
        types.push(key.toString());
        result.push(value.toString());
      }
    });

    let ids = [];

    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      updateExamination(types, result, props.state.id, ids);
    } else {
      saveExamination(types, result, props.state.id);
    }
  };
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;

  let isAdmni = userInfo.user_info.user_type == "lab-admin";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <div className="card">
            <div className="card-header bg-info">
              <h4 className="text-uppercase d-inline-block">Examination </h4>
            </div>

            {/**/}

            {/**/}
            <div className="card-body m-auto">
              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="height">height</label>
                  <input
                    disabled={isComplete}
                    placeholder="height"
                    type="number"
                    step="any"
                    value={state.height}
                    onChange={(e) => {
                      setState({
                        ...state,
                        height: e.target.value,
                        BMI: calculateBMI(e.target.value, state.weight).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="weight">weight</label>
                  <input
                    placeholder="weight"
                    type="number"
                    step="any"
                    disabled={isComplete}
                    value={state.weight}
                    onChange={(e) => {
                      setState({
                        ...state,
                        weight: e.target.value,
                        BMI: calculateBMI(state.height, e.target.value).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="BMI">BMI </label>
                  <input
                    placeholder="BMI"
                    type="number"
                    step="any"
                    disabled
                    value={state.BMI}
                    onChange={(e) => {
                      setState({ ...state, BMI: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>

              <hr />

              {isAdmni == false ? null : (
                <div>
                  <div className="jumbotron pl-1 m-1 py-3">
                    <h4>Gynecology Obstetric</h4>
                    <hr />
                    <div className="row w-100">
                      <div className="form-group mr-2 ml-2 ">
                        <label htmlFor="rbs">LMP</label>

                        <input
                          type="date"
                          id="start"
                          name="trip-start"
                          className="form-control ml-1 mb-1"
                          onChange={(date) => {
                            setState({ ...state, lmp: date.target.value });
                          }}
                          value={state.lmp}
                        />

                        <textarea
                          disabled={isComplete}
                          placeholder="LMPText"
                          value={state.LMPText}
                          onChange={(e) => {
                            setState({ ...state, LMPText: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                      <div className="form-group mr-2 ml-2 ">
                        <label htmlFor="Hba1c">EDD</label>
                        <input
                          type="date"
                          id="start"
                          name="trip-start"
                          className="form-control ml-1 mb-1"
                          onChange={(date) => {
                            setState({ ...state, EDD: date.target.value });
                          }}
                          value={state.EDD}
                        />

                        <textarea
                          placeholder="eddText"
                          disabled={isComplete}
                          value={state.eddText}
                          onChange={(e) => {
                            setState({ ...state, eddText: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>
                    </div>
                    <textarea
                      placeholder="other"
                      disabled={isComplete}
                      value={state.otherGynecologyObstetric}
                      onChange={(e) => {
                        setState({
                          ...state,
                          otherGynecologyObstetric: e.target.value,
                        });
                      }}
                      className={`form-control shadow-none  ml-1 mr-1`}
                    />
                  </div>
                  <hr />

                  <hr />
                </div>
              )}
              {/*stop from here examination*/}

              <button
                className="btn btn-success w-100 mt-4"
                type="submit"
                disabled={isComplete}
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ExaminationSection;
