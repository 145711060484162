import React, { useEffect } from "react";
import "./../todayPatient.css";
import ProfileVisit from "../Profile/ProfileVisit";
import HestoryVisit from "../History/MedicalHistory";
import DoctorVisitForm from "./DoctorVisitForm";
import {
  getPatientVisitis,
  SET_STATE_TO_LOADING,
} from "./create_visit_reducer";
import { useDispatch, useSelector } from "react-redux";
import Slideshow from "../../showImageSilde/ImageSilde";
import PatientCharts from "../Charts/PatientCharts";

const TabsVisit = (props) => {
  const id = props.match.params.id;
  const userlogin = useSelector((state) => state.userLogin);
  const state = useSelector((state) => state.eachVisitDetalis);

  const isLoaded = useSelector((state) => state.eachVisitDetalis?.isLoaded);
  const found = useSelector((state) => state.eachVisitDetalis?.found);
  const dispatch = useDispatch();

  let patient = state?.patient;
  useEffect(() => {
    dispatch({ type: SET_STATE_TO_LOADING });
    getPatientVisitis(id);
  }, []);
  //
  let list = [];

  state?.visits?.forEach((e, index) => {
    list.push(
      <a
        key={e.id}
        className="list-group-item list-group-item-action text-red"
        id={e.created_at.toString()}
        data-toggle="list"
        href={`#index${index}`}
        role="tab"
        aria-controls="settings"
      >
        {e.created_at.toString().split(" ")[0]}
      </a>
    );
  });
  let listForms = [];

  state?.visits?.forEach((e, index) => {
    listForms.push(
      <div
        key={e.id}
        className="tab-pane fade"
        id={`index${index}`}
        role="tabpanel"
      >
        <DoctorVisitForm state={e} />
      </div>
    );
  });

  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;
  return isLoaded !== true ? (
    <div>Loading</div>
  ) : found == true ? (
    <div className="m-auto">
      <div className="card">
        <div className="card-header bg-info ">
          <div className="text-bold  text-center w-100 row styleTopInfo">
            <div>ID: {state.patient.patient.id}</div>
            <div>Code: {state.patient.patient.code}</div>
            <div>Full Name: {state.patient.patient.fullname} </div>
            <div>Mobile :{state.patient.patient.phone}</div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-3 col-xs-12 mb-2">
              <div className="list-group" id="list-tab" role="tablist">
                <a
                  className="list-group-item list-group-item-action active"
                  id="list-home-list"
                  data-toggle="list"
                  href="#list-home"
                  role="tab"
                  aria-controls="home"
                >
                  Profile
                </a>
                {userInfo.user_info.user_type == "lab-admin" ? (
                  <a
                    className="list-group-item list-group-item-action"
                    id="list-profile-list"
                    data-toggle="list"
                    href="#list-profile"
                    role="tab"
                    aria-controls="profile"
                  >
                    Medical History
                  </a>
                ) : null}
                <a
                  className="list-group-item list-group-item-action"
                  id="list-show-list"
                  data-toggle="list"
                  href="#list-show"
                  role="tab"
                  aria-controls="show"
                >
                  CVS Images Slide
                </a>
                <a
                  className="list-group-item list-group-item-action"
                  id="list-messages-list"
                  data-toggle="list"
                  href="#list-messages"
                  role="tab"
                  aria-controls="messages"
                >
                  Charts
                </a>
                {list}
              </div>
            </div>
            <div className="col-lg-10 col-md-9  col-sm-9 col-xs-12 border-left">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="list-home"
                  role="tabpanel"
                  aria-labelledby="list-home-list"
                >
                  <ProfileVisit state={state.patient} />
                </div>
                <div
                  className="tab-pane fade"
                  id="list-profile"
                  role="tabpanel"
                  aria-labelledby="list-profile-list"
                >
                  {userInfo.user_info.user_type == "lab-admin" ? (
                    <HestoryVisit
                      state={state?.medical_history}
                      patient_id={state?.patient?.patient?.id}
                    />
                  ) : null}
                </div>
                <div
                  className="tab-pane fade"
                  id="list-show"
                  role="tabpanel"
                  aria-labelledby="list-show-list"
                >
                  <Slideshow state={state.patient} />
                </div>

                <div
                  className="tab-pane fade"
                  id="list-messages"
                  role="tabpanel"
                  aria-labelledby="list-messages-list"
                >
                  <PatientCharts state={state.patient} />
                </div>
                {listForms}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>Cannot Find thid Patinet</div>
  );
};

export default TabsVisit;
