export const ADD_OCCUPATION_REQUEST = 'ADD_OCCUPATION_REQUEST';
export const ADD_OCCUPATION_SUCCESS = 'ADD_OCCUPATION_SUCCESS';
export const ADD_OCCUPATION_FAIL = 'ADD_OCCUPATION_FAIL';

export const GET_OCCUPATION_REQUEST = 'GET_OCCUPATION_REQUEST';
export const GET_OCCUPATION_SUCCESS = 'GET_OCCUPATION_SUCCESS';
export const GET_OCCUPATION_FAIL = 'GET_OCCUPATION_FAIL';

export const UPDATE_OCCUPATION_REQUEST = 'UPDATE_OCCUPATION_REQUEST';
export const UPDATE_OCCUPATION_SUCCESS = 'UPDATE_OCCUPATION_SUCCESS';
export const UPDATE_OCCUPATION_FAIL = 'UPDATE_OCCUPATION_FAIL';
