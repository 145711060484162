export const ADD_PATION_REQUEST = 'ADD_PATION_REQUEST';
export const ADD_PATION_SUCCESS = 'ADD_PATION_SUCCESS';
export const ADD_PATION_FAIL = 'ADD_PATION_FAIL';

export const GET_PATION_REQUEST = 'GET_PATION_REQUEST';
export const GET_PATION_SUCCESS = 'GET_PATION_SUCCESS';
export const GET_PATION_FAIL = 'GET_PATION_FAIL';

export const DELETE_PATION_REQUEST = 'DELETE_PATION_REQUEST';
export const DELETE_PATION_SUCCESS = 'DELETE_PATION_SUCCESS';
export const DELETE_PATION_FAIL = 'DELETE_PATION_FAIL';

export const UPDATE_PATION_REQUEST = 'UPDATE_PATION_REQUEST';
export const UPDATE_PATION_SUCCESS = 'UPDATE_PATION_SUCCESS';
export const UPDATE_PATION_FAIL = 'UPDATE_PATION_FAIL';
